import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { secrets } from '../../../environments/secrets';

export const httpInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.url.startsWith('http') || req.url.indexOf('.svg') > 0) {
    return next(req);
  }

  let headers = req.headers
    .append('partner', environment.partner);

  if (!headers.has('x-api-key')) {
    headers = headers.append('x-api-key', secrets.API_KEY);
  }

  const clonedRequest = req.clone({
    headers,
    url: `${environment.baseEndpoint}${req.url}`
  });

  return next(clonedRequest);
};
