import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { provideServiceWorker } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { provideDayjsDateAdapter } from './shared/date-adapter/dayjs-adapter-provider';
import { httpInterceptor } from './shared/utils/http.interceptor';
import { LocationStrategy } from '@angular/common';
import { PathPreserveQueryLocationStrategy } from './shared/utils/preserve-query-location-strategy';
import { provideClientHydration } from '@angular/platform-browser';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    { provide: LocationStrategy, useClass: PathPreserveQueryLocationStrategy },
    provideAnimations(),
    provideHttpClient(withFetch(), withInterceptors([httpInterceptor])),
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.updatesEnabled,
      registrationStrategy: 'registerWhenStable:30000'
    }),
    provideDayjsDateAdapter(), provideClientHydration()
  ]
};
