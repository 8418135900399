import { ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { MatIcon, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { LoaderService } from './shared/services/loader.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { DayjsService } from './shared/services/dayjs.service';
import { MatDialog } from '@angular/material/dialog';
import { ActionDialogComponent } from './shared/components/dialogs/action-dialog/action-dialog.component';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Subject, filter, switchMap, take, takeUntil } from 'rxjs';
import { VhPage, getVhUrl } from './shared/utils/url-helper';
import { CurrentDestinationService } from './shared/services/current-destination.service';
import { PropertyFiltersService } from './shared/services/property-filters.service';
import { PrimaryHeaderComponent } from './shared/components/primary-header/primary-header.component';
import { DestinationInputComponent } from './shared/components/form-helpers/controls/destination-input/destination-input.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFabButton, MatIconButton } from '@angular/material/button';
import { BreakpointObserver } from '@angular/cdk/layout';
import { SCREEN_MD_MAX } from './shared/constants/misc';
import { PropertyFiltersDialogComponent } from './destinations/components/dialogs/property-filters-dialog/property-filters-dialog.component';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { PlatformService } from './shared/services/platform.service';
import { MatTooltip } from '@angular/material/tooltip';
import { ISuggestion } from './shared/models/api-models/suggestion';

@Component({
  selector: 'vh-root',
  standalone: true,
  imports: [
    RouterOutlet,
    MatProgressSpinner,
    NgIf,
    PrimaryHeaderComponent,
    MatIcon,
    DestinationInputComponent,
    MatIconButton,
    MatFabButton,
    MatTooltip,
    AsyncPipe,
    ReactiveFormsModule
  ],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('class') cssClasses = 'flex flex-col w-full h-full';

  public appTitle = environment.appTitle;
  public faqUrl = getVhUrl(VhPage.Faq);
  public homeUrl = getVhUrl(VhPage.Home);
  public showLoader = false;
  public isHomeUrl = false;
  public isUrlKnown = false;
  public isDestinationsUrl = false;
  public moveChatUp = false;

  public destinationControl = new FormControl<ISuggestion | null>(null);

  private _unsubscribe = new Subject<void>();

  private readonly SM_OR_MD_QUERY = `(min-width: 0px) and (max-width: ${SCREEN_MD_MAX}px)`;

  constructor(
    public loader: LoaderService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    dayjsService: DayjsService,
    private _dialog: MatDialog,
    updateService: SwUpdate,
    private cd: ChangeDetectorRef,
    private router: Router,
    public destService: CurrentDestinationService,
    public filters: PropertyFiltersService,
    private _bo: BreakpointObserver,
    gaService: GoogleAnalyticsService,
    private _platform: PlatformService) {
    dayjsService.init();
    gaService.initialize();

    iconRegistry.addSvgIconSet(sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons.svg'));
    iconRegistry.addSvgIconSet(sanitizer.bypassSecurityTrustResourceUrl('assets/images/card-icons.svg'));

    /**
     * The updateService.versionUpdates will be triggered only on QA and Prod because dev will always have the latest version.
     * This is configured in angular.json.
     */
    updateService.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        take(1),
        switchMap(() => this._dialog.open(ActionDialogComponent, {
          data: {
            title: 'New version available',
            message: `There is a newer application version available. 
            You can update now by choosing "yes", or wait until the next application 
            usage to have the latest version. Do you want to update now?`
          },
          maxWidth: '400px'
        }).afterClosed().pipe(take(1)))
      )
      .subscribe((canUpdate: boolean) => {
        if (canUpdate && this._platform.isBrowser) {
          document.location.reload();
        }
      });


    this.detectPageChanges();
  }

  public ngOnInit(): void {
    this.loader.state$.pipe(takeUntil(this._unsubscribe)).subscribe(state => {
      this.showLoader = state.show;
      this.cd.detectChanges();
    });

    this.destService.currentDestination$
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(dest => {
        this.destinationControl?.setValue(dest, { emitEvent: false });

        this.checkRedirectToDestinations();
      });

    this.destinationControl.valueChanges.pipe(takeUntil(this._unsubscribe)).subscribe(dest => {
      this.destService.setCurrentDestination(dest);
    });
  }

  public openFilters() {
    if (this._bo.isMatched(this.SM_OR_MD_QUERY) || (this.isUrlKnown && !this.isHomeUrl && !this.isDestinationsUrl)) {
      this._dialog.open(PropertyFiltersDialogComponent, {
        width: '99%',
        maxWidth: '600px'
      }).afterClosed()
        .pipe(take(1))
        .subscribe(filtersChanged => {
          if (filtersChanged) {
            this.checkRedirectToDestinations();
          }
        });
    } else {
      this.filters.toggleFiltersPanel();
    }
  }

  public ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  public openChat() {
    if (this._platform.isBrowser) {
      window.open(environment.chatUrl, undefined, environment.chatOptions);
    }
  }

  private detectPageChanges() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this._unsubscribe)
    ).subscribe(event => {
      let url = (event as NavigationEnd).url;
      url = url.split('?')[0];

      this.isHomeUrl = (url.endsWith(VhPage.Home) || url.endsWith('/'));
      this.isUrlKnown = true;
      this.isDestinationsUrl = (url.endsWith(VhPage.Destinations) || url.endsWith('/'));
      this.moveChatUp = url.includes(VhPage.Property) || url.includes(VhPage.Book);
    });
  }

  private checkRedirectToDestinations() {
    if (this.isUrlKnown && !this.isHomeUrl && !this.isDestinationsUrl) {
      this.router.navigate([getVhUrl(VhPage.Destinations)], { queryParamsHandling: 'preserve' });
    }
  }
}
