import { CanDeactivateFn } from '@angular/router';
import { UnsavedChangesDirective } from '../directives/unsaved-changes.directive';
import { Observable } from 'rxjs';

export const unsavedChangesDeactivateGuard: CanDeactivateFn<UnsavedChangesDirective> = (component: UnsavedChangesDirective): Observable<boolean> | boolean => {
  if (!component.canDeactivate) {
    return confirm('Are you sure you want to leave this page?');
  }

  return component.canDeactivate();
};
