<h1 mat-dialog-title class="!tracking-normal">{{data?.title}}</h1>

<mat-dialog-content>
    <p class="pt-4 pb-2 text-vhGray-100">{{ data?.message }}</p>
</mat-dialog-content>

<div mat-dialog-actions class="flex !justify-end !items-center !space-x-3 !px-6 !pb-6 !pt-0">
    <ng-container *ngFor="let button of data?.buttons">
        <button *ngIf="!button.color" mat-stroked-button [mat-dialog-close]="button.dialogResult" type="button"
            [aria-label]="button.caption">
            <span class="text-vhGray-100 font-semibold tracking-normal">{{button.caption}}</span>
        </button>

        <button *ngIf="button.color" cdkFocusInitial mat-flat-button [mat-dialog-close]="button.dialogResult"
            type="button" [color]="button.color" [aria-label]="button.caption" class="font-semibold tracking-normal">
            <span class="font-semibold tracking-normal">{{button.caption}}</span>
        </button>
    </ng-container>
</div>