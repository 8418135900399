import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PlatformService } from './platform.service';

declare const gtag: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService implements OnDestroy {
  private _unsubscribe = new Subject<void>();

  constructor(
    private router: Router,
    private _platform: PlatformService) {
  }

  public ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  public initialize() {
    if (!this._platform.isBrowser) {
      return;
    }

    this.onRouteChange();

    try {
      const gTagScript = document.createElement('script');
      gTagScript.async = true;
      gTagScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gaTrackingId}`;

      document.head.appendChild(gTagScript);

      const dataLayerScript = document.createElement('script');
      dataLayerScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${environment.gaTrackingId}', {'send_page_view': false});`;

      document.head.appendChild(dataLayerScript);
    } catch (e) {
      console.error('Error adding Google Analytics', e);
    }
  }

  // use gtag.js to send Google Analytics Events
  public trackEvent(action: string, eventCategory?: string, eventLabel?: string, value?: string) {
    if (!this._platform.isBrowser) {
      return;
    }

    gtag('event', action, {
      ...(eventCategory && { event_category: eventCategory }),
      ...(eventLabel && { event_label: eventLabel }),
      ...(value && { value: value }),
    });
  }

  private onRouteChange() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this._unsubscribe)
    ).subscribe(event => {
      gtag('config', environment.gaTrackingId, {
        page_path: event.urlAfterRedirects,
      });
    });
  }
}
