import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from "@angular/material/core";
import { MAT_DAYJS_DATE_FORMATS } from "./dayjs-date-formats";
import { DayJsDateAdapterOptions, DayjsDateAdapter, MAT_DAYJS_DATE_ADAPTER_OPTIONS } from "./dayjs-date-adapter";
import { Provider } from "@angular/core";

export function provideDayjsDateAdapter(
    formats: MatDateFormats = MAT_DAYJS_DATE_FORMATS,
    options?: DayJsDateAdapterOptions,
): Provider[] {
    const providers: Provider[] = [
        {
            provide: DateAdapter,
            useClass: DayjsDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: formats },
    ];

    if (options) {
        providers.push({ provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: options });
    }

    return providers;
}