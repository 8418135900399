import { Injectable } from '@angular/core';

import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import weekDay from "dayjs/plugin/weekday";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isBetween from "dayjs/plugin/isBetween";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import minMax from "dayjs/plugin/minMax";

import dayjs from "dayjs";

/**
 * Imports all needed modules in the app and extends dayjs with them so we don't have to do it in every component.
 * Call init once at the start of the app.
 */
@Injectable({
  providedIn: 'root'
})
export class DayjsService {
  public init() {
    dayjs.extend(isSameOrAfter);
    dayjs.extend(isBetween);
    dayjs.extend(isSameOrBefore);
    dayjs.extend(weekDay);
    dayjs.extend(customParseFormat);
    dayjs.extend(utc);
    dayjs.extend(minMax);
  }
}
