<div *ngIf="showLoader" class="fixed z-[9999] w-full h-full bg-black/50 flex justify-center items-center select-none">
    <mat-progress-spinner mode="indeterminate">
    </mat-progress-spinner>
</div>

<vh-primary-header [isWhite]="true" *ngIf="isUrlKnown && !isHomeUrl">
    <div class="flex items-center border border-solid border-vhNeutral-95 rounded px-2 py-1 w-full md:rounded-[30px] md:px-4 lg:min-w-[300px] xl:min-w-[500px]"
        [class.animate-border-neutral95-to-primary60]="formFieldControl?.focused">
        <mat-icon *ngIf="(destService.currentDestination$ | async) === null; else haveSelection" svgIcon="vhc-location"
            class="mr-[10px] min-w-6" [class.animate-neutral10-to-vh-primary]="formFieldControl?.focused"
            (click)="formFieldControl?.onContainerClick();" tabindex="-1"
            (keydown)="formFieldControl?.onContainerClick(); "></mat-icon>

        <ng-template #haveSelection>
            <button matTooltip="Clear destination" matTooltipClass="vh-tooltip" aria-label="Clear destination"
                class="mr-[10px] outline-none border-none p-0 cursor-pointer bg-transparent hover:text-vhPrimary-60"
                (click)="$event.stopPropagation(); destinationControl.setValue(null)">
                <mat-icon svgIcon="vhc-clear-location"></mat-icon>
            </button>
        </ng-template>

        <div class="flex flex-col grow max-w-full" (click)="formFieldControl?.onContainerClick(); "
            (keydown)="formFieldControl?.onContainerClick(); " tabindex="-1">
            <label [attr.for]="formFieldControl" class="text-sm mb-2 text-vhNeutral-50 cursor-pointer"
                [class.animate-neutral50-to-vh-primary]="formFieldControl?.focused">Destination</label>

            <vh-destination-input #formFieldControl placeholder="Where to go?"
                [formControl]="destinationControl"></vh-destination-input>
        </div>

        <button mat-icon-button (click)="openFilters(); $event.stopPropagation()" aria-label="Open filters"
            class="ml-[10px]">
            <mat-icon svgIcon="vhc-filters"
                [class.animate-neutral10-to-vh-primary]="formFieldControl?.focused"></mat-icon>
        </button>
    </div>
</vh-primary-header>

<button *ngIf="isUrlKnown" mat-fab color="primary" class="!absolute z-50 bottom-8 left-8 lg:!bottom-8" (click)="openChat()"
    [class.!bottom-28]="moveChatUp">
    <div class="flex items-center">
        <mat-icon svgIcon="vhc-chat"></mat-icon>
    </div>
</button>

<div class="w-full h-full overflow-hidden">
    <router-outlet></router-outlet>
</div>