import { Routes } from '@angular/router';
import { unsavedChangesDeactivateGuard } from './shared/guards/unsaved-changes-deactivate.guard';
import { environment } from '../environments/environment';

/**
 * SEE README.MD FOR WHAT TO DO WHEN ADDING A NEW PAGE OR EDITING THE ROUTE FOR AN EXISTING ONE.
 */
export const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    {
        path: 'home',
        title: `${environment.appTitle}`,
        loadComponent: () => import('./home/components/home/home.component')
            .then(mod => mod.HomeComponent)
    },
    {
        path: 'destinations',
        title: `${environment.appTitle} - destinations`,
        loadComponent: () => import('./destinations/components/destinations.component')
            .then(mod => mod.DestinationsComponent),
        canDeactivate: []
    },
    {
        path: 'property/:id',
        title: `${environment.appTitle} - property`,
        loadComponent: () => import('./property/components/property/property.component')
            .then(mod => mod.PropertyComponent),
        canDeactivate: []
    },
    {
        path: 'supplier/:id',
        title: `${environment.appTitle} - supplier`,
        loadComponent: () => import('./supplier/components/supplier-details/supplier-details.component')
            .then(mod => mod.SupplierDetailsComponent),
        canDeactivate: []
    },
    {
        path: 'listing/:id',
        title: `${environment.appTitle} - book online`,
        loadComponent: () => import('./reservations/components/create-reservation/create-reservation.component')
            .then(mod => mod.CreateReservationComponent),
        canDeactivate: [unsavedChangesDeactivateGuard]
    },
    {
        path: 'faq',
        title: `${environment.appTitle} - FAQ`,
        loadComponent: () => import('./faq/components/faq.component')
            .then(mod => mod.FaqComponent)
    },
    {
        path: '**',
        title: `${environment.appTitle} - page not found`,
        pathMatch: 'full',
        loadComponent: () => import('./not-found/not-found.component')
            .then(mod => mod.NotFoundComponent)
    }
];
