import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';


export interface IActionDialogButton {
  caption: string;
  dialogResult: any;
  color?: 'primary' | 'accent' | 'warn';
}

export interface IActionDialogData {
  title: string;
  message: string;
  buttons?: IActionDialogButton[];
}

@Component({
  selector: 'vh-action-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButton,
    NgFor,
    NgIf
  ],
  templateUrl: './action-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionDialogComponent {
  public data: IActionDialogData | null = null;

  constructor(@Inject(MAT_DIALOG_DATA) dialogData: IActionDialogData) {
    this.data = {
      title: dialogData?.title || 'Unsaved changes',
      message: dialogData?.message || 'Changes you made may not be saved. Are you sure you want to leave?',
      buttons: dialogData?.buttons ??
        [{ caption: 'No', dialogResult: false }, { caption: 'Yes', dialogResult: true, color: 'primary' }]
    };
  }
}
